import { KeyboardArrowRight } from '@finn/design-system/icons/keyboard-arrow-right';
import React from 'react';

type Props = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const TestimonialSliderDesktopNextButton = ({ onClick }: Props) => (
  <button
    type="button"
    onClick={onClick}
    className="bg-pewter hover:bg-steel inline-flex h-20 w-20 cursor-pointer items-center justify-center rounded-full border-0 p-6 text-white transition-colors duration-100 ease-in-out"
  >
    <KeyboardArrowRight className="min-h-14 min-w-14 fill-white" />
  </button>
);

export default TestimonialSliderDesktopNextButton;
